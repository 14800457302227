import {ElNotification} from 'element-plus';
const verifyChainId = (chainId)=>{
	if(chainId!='0x38'){
		ElNotification({
		    title: 'Prompt',
		    message: 'The current network is not an Binance Smart Chain, please switch to this Binance Smart Chain and check againe',
			customClass:'msgClass',
			duration:6000
		})
		return false;
	}
	else{
		return true;
	}
}


export default {
	verifyChainId
}