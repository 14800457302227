<template>
	<div class="container">
		<div class="paper">
			<!-- <img class="w-100" :src="require('../../assets/img/banner_invite.png')" alt=""> -->
			<h1 class="text-center">{{store.state.lang.MyInvitations}}</h1>
			<h3 class="mt-4 text-center">
			{{store.state.lang.DirectInvitation}}:{{daoIndex == 0?inviteNodeList.length:inviteDaoList.length}}
		</h3>
			<el-divider />
			<h3 class="mb-5">{{store.state.lang.TotalRewards}}:</h3>
			
			<div class="row ls-flex-ac mb-4" v-for="(item,index) in rewardsList" :key="index">
				<div class="col-md-2 col-sm-4 col-4  ls-flex-ac invite-coin">
					<img :src="item.icon" alt="">
					{{item.name}}
				</div>
				<div class="col-md-7 col-sm-8 col-8 receive-num ls-flex-ac">
					<!-- <div class="ls-flex-ac">Received：0</div> -->
					<div class="ls-flex-ajc">{{store.state.lang.PendingTxt}}：{{item.balanceFm}}</div>
				</div>
				<div class="col-md-3 col-sm-12 col-12 receive-box text-center">
					<!-- <div class="p-num">Pending：{{item.balance}}</div> -->
					<div class="receive-btn" v-if="index==0" @click="withdrawW3C(item.balance)">{{store.state.lang.ReceiveTxt}}</div>
					<div class="receive-btn" v-else @click="claim(item.addr,item.balance)">{{store.state.lang.ReceiveTxt}}</div>
				</div>
			</div>
			
			
			<div class="row mt-5">
				<div class="col-12 col-sm-12 offset-md-2 col-md-8">
					<div class="invite-url ls-flex-ajc">{{inviteUrlVal}}</div>
					<div class="mt-4">
						<div class="copy-invite-btn ls-flex-ajc" @click="copyIviteUrl()">{{store.state.lang.CopyTxt}}</div>
					</div>
				</div>
			</div>
			
			<h1 class="mt-5">
				<div class="text-center">Invitation Details</div>
				<!-- <div class="show-btn" @click="changeDetailState()" :class="{'active':showDetail}"></div> -->
			</h1>
			
			<div class="asset-bar ls-flex-ajc mt-4" style="margin: 0 auto;">
				<div class="ls-flex-ajc asset-bar-item ls-flex-1" :class="{'active':daoIndex==0}" @click="changeAssetIndex(0)">Nodes</div>
				<div class="ls-flex-ajc asset-bar-item ls-flex-1 ml-4" v-if="nftId<500" :class="{'active':daoIndex==1}" @click="changeAssetIndex(1)">DAOs</div>
			</div>
			
			<div class="mt-4 text-center" v-if="daoIndex == 0">
				<div class="mt-3" style="overflow-x: auto;" v-if="inviteNodeList.length>0" v-for="(item,index) in inviteNodeList">
					{{item}}
				</div>
				<div v-else>{{store.state.lang.inviteTips}}</div>
			</div>
			
			<div class="mt-4 text-center" v-if="daoIndex == 1">
				<div class="mt-3" style="overflow-x: auto;" v-if="inviteDaoList.length>0" v-for="(item,index) in inviteDaoList">
					{{item}}
				</div>
				<div v-else>{{store.state.lang.inviteTips}}</div>
			</div>
		</div>
	</div>
	
	<comModal :showState="showModalState" :State="stateNum" :StateInfo="StateInfo" :tHash="tHash"></comModal>
</template>

<script setup>
	import {ref,onMounted} from 'vue';
	import Web3 from "web3";
	import {useRoute} from 'vue-router';
	import useClipboard from 'vue-clipboard3';
	import { inviteUrl,stackeAddr,relationAddr,W3CAddr,W3BAddr,approveUsdtAddr} from '@/utils';
	import { relationAbi} from '@/utils/relationAbi.json';
	import { stackeAbi} from '@/utils/stackeAbi.json';
	import { ElMessage} from 'element-plus';
	import {useStore} from 'vuex';
	import formate from "@/utils/formate.js";
	import verify from '@/utils/verify.js';
	import comModal  from "@/components/com-modal/com-modal.vue";
	
	const showModalState = ref(false);
	const StateInfo = ref('');
	const stateNum = ref(0);
	const tHash = ref('');
	
	const store = useStore();
	const { toClipboard } = useClipboard();
	const route = useRoute();
	
	const daoIndex = ref(0);
	const showDetail = ref(true);
	const inviteUrlVal = ref('');
	const nftId = ref('');
	const inviteDaoList = ref([]);
	const inviteNodeList = ref([]);
	const rewardsList = ref([
		{
			icon:require('../../assets/img/icon/icon_logo.png'),
			name:'W3C',
			balance:0,
			balanceFm:0,
			addr:W3CAddr
		},
		{
			icon:require('../../assets/img/icon/icon_logo.png'),
			name:'W3B',
			balance:0,
			balanceFm:0,
			addr:W3BAddr
		},
		{
			icon:require('../../assets/img/icon_USDT.png'),
			name:'USDT',
			balance:0,
			balanceFm:0,
			addr:approveUsdtAddr
		},
	]);
	
	import {provider} from "@/utils/provider.js";
	
	var web3js;
	if(store.state.isNet == 1){
		if (typeof window.ethereum === "undefined") {
			//没安装MetaMask钱包进行弹框提示
			web3js = new Web3();
		} else {
			web3js = new Web3(ethereum);
		}
	}
	else{
		web3js = new Web3(provider);
	}
	
	const stakeContract = new web3js.eth.Contract(stackeAbi,stackeAddr);
	const relationContract = new web3js.eth.Contract(relationAbi,relationAddr);
	
	const changeAssetIndex = (index)=>{
		daoIndex.value = index;
	}
	
	const changeDetailState = ()=>{
		showDetail.value = !showDetail.value ;
	}
	
	const initRewardList = ()=>{
		rewardsList.value.map((item,index)=>{
			if(index==0){
				stakeContract.methods.viewEarnedNFT(route.params.id).call().then((res)=>{
					item.balance = res;
					item.balanceFm = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'),4);
					return item;
				})
			}
			else{
				relationContract.methods.bonus(route.params.id,item.addr).call().then((res)=>{
					item.balance = res;
					item.balanceFm = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'),4);
					return item;
				})
			}
		})
	}
	
	const getDaoLen = ()=>{
		relationContract.methods.daoInvListLength(nftId.value).call().then((res)=>{
			relationContract.methods.getDaoInvList(nftId.value).call().then((result)=>{
				inviteDaoList.value = result;
				
			})
		})
	}
	const getNodeLen = ()=>{
		relationContract.methods.nodeInvListLength(nftId.value).call().then((res)=>{
			relationContract.methods.getNodeInvList(nftId.value).call().then((result)=>{
				inviteNodeList.value = result;
			})
		})
	}
	
	onMounted(()=>{
		nftId.value = route.params.id;
		inviteUrlVal.value = inviteUrl + route.params.id;
		
		initRewardList();
		
		getDaoLen();
		getNodeLen();
	})
	
	const copyIviteUrl = async () => {
	  try {
		await toClipboard(inviteUrlVal.value );
		ElMessage('Copied to clipboard');
	  } catch (e) {
		console.error(e)
	  }
	}
	
	const withdrawW3C = (balance)=>{
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		if(balance == 0){
			ElMessage('There is no amount available for receiving');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Receive';
		stateNum.value = 1;
		
		stakeContract.methods.withdrawNFT(route.params.id).send({
			from:store.state.account
		}).then((res)=>{
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			initRewardList();
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
	const claim = (addr,balance)=>{
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		if(balance == 0){
			ElMessage('There is no amount available for receiving');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Receive';
		stateNum.value = 1;
		
		relationContract.methods.claim(route.params.id,addr).send({
			from:store.state.account
		}).then((res)=>{
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			initRewardList();
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
</script>

<style scoped>
	.paper{
		padding:30px;
	}
	.invite-coin{
		font-weight: 500;
		color: #fff;
		font-size: 20px;
	}
	.invite-coin img{
		width: 32px;
		margin-right: 10px;
	}
	.receive-num div{
		height:30px;
		line-height: 30px;
		background: linear-gradient(90deg, #2E0000, #FF0000);;
		border-radius: 15px;
		padding: 0px 20px;
		font-size: 12px;
		font-family: Axiforma-Bold, Axiforma;
		font-weight: bold;
		color: rgb(255, 255, 255);
		flex: 1 1 0%;
	}
	.receive-box .p-num{
		color: #fff;
		font-size: 16px;
		font-weight: 500;
	}
	.receive-box .receive-btn{
		height: 32px;
		padding: 0 30px;
		background: #620000;
		border: 1px solid #FF0000;
		border-radius: 16px;
		font-size: 16px;
		font-weight: 500;
		color: #fff;
		cursor: pointer;
	}
	.invite-url{
		width: 100%;
		height: 40px;
		margin: 0 auto;
		background: #620000;
		border-radius: 20px;
		font-size: 16px;
		font-weight: 500;
		color: rgb(255, 255, 255);
		padding: 0px 25px;
	}
	.copy-invite-btn{
		width: 200px;
		height: 40px;
		margin: 0 auto;
		background: #620000;
		border: 1px solid #FF0000;
		border-radius: 20px;
		font-size: 18px;
		font-weight: bold;
		color: rgb(255, 255, 255);
		cursor: pointer;
	}
	.show-btn{
		width: 60px;
		height: 30px;
		border-radius: 15px;
		transition: all 0.3s ease 0s;
		border: 2px solid #620000;
		background: #fff;
		cursor: pointer;
		background-image: url('../../assets/img/icon/icon_o.png');
		background-size: 20px;
		background-position: center;
		background-repeat: no-repeat;
	}
	.show-btn.active{
		background: #620000;
		border: 0px solid #620000;
		background-image: url('../../assets/img/icon_c.png');
		background-size: 18px;
		background-position: center;
		background-repeat: no-repeat;
	}
	:deep .el-divider--horizontal{
		border-top: 1px solid #FF0000;
	}
	@media (max-width:768px) {
		.paper{
			padding:15px;
		}
		.pd-5{
			padding:25px;
			box-sizing: border-box;
		}
		.receive-box{
			margin-top: 15px;
		}
	}
</style>